import React from 'react'

function HeaderMarque() {
  return (
    <header>
    <div class="header--top  ">
        <img src="images/4.jpg" alt="header" />

        <div class="overlay">
            <div class="container">
                <div class="title--infos">
                    <h1>A propos</h1>

                    {/* <nav  aria-label="breadcrumb">
                        <ol class="breadcrumb">
                          <li class="breadcrumb-item"><a href="index.html">Le groupe |</a></li>
                          <li class="breadcrumb-item active" aria-current="page">La marque</li>
                        </ol>
                      </nav> */}
                </div>
            </div>
        </div>
    </div>
</header>
  )
}

export default HeaderMarque