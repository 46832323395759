import React from "react";

function MainMetier() {
  return (
    <>
      <main>
        <section>
          <div class="container wow fadeInRight">
            <div class="section--title">
              <h1>
                Nous Sommes <span>Leader</span> 
              </h1>
            </div>

            <div class="row">
              <div class="col-md-6 col-lg-6">
                <div class="card-index">
                  <div class="card-index__img">
                    <img
                      src="images/6.jpg"
                      alt="img"
                    />
                  </div>

                  <div class="card-index__descrip">
                    <h3>Electricité</h3>

                    <p>
                      Bienvenue dans le monde de l’électricité ! Nous sommes
                      ravis de vous présenter notre large gamme de matériel
                      électrique de qualité supérieure, conçue pour répondre à
                      tous vos besoins en matière d’électricité. Nous savons que
                      la sécurité est une priorité absolue lorsqu’il s’agit de
                      travailler avec l’électricité, c’est pourquoi nous ne
                      proposons que des produits qui répondent aux normes les
                      plus strictes en matière de sécurité. Nous sommes fiers de
                      proposer des produits de haute qualité à des prix
                      compétitifs pour tous les projets, des plus simples aux
                      plus complexes.
                    </p>
                  </div>
                </div>
              </div>

              <div class="col-md-6 col-lg-6">
                <div class="card-index">
                  <div class="card-index__img">
                    <img
                      src="images/5.jpg"
                      alt="img"
                    />
                  </div>

                  <div class="card-index__descrip">
                    <h3>Plomberie</h3>
                    <p>
                      Bienvenue dans notre sélection de matériel de plomberie.
                      Nous sommes fiers de vous proposer une gamme complète
                      d’outils et de fournitures pour répondre à tous vos
                      besoins en matière de plomberie, que vous soyez un
                      professionnel ou un amateur. Notre gamme de produits
                      comprend des tuyaux en PVC, en cuivre et en acier
                      inoxydable, ainsi que des raccords, des vannes, des pompes
                      et des chauffe-eau. Nous avons également une variété de
                      robinets, douches et accessoires de salle de bains pour
                      améliorer votre espace de vie.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </main>
    </>
  );
}

export default MainMetier;
