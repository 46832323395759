import React from "react";

function MainMarque() {
  return (
    <>
      <main>
        <section>
          <div class="container wow fadeInLeft">
            <div class="row d-flex justify-content-center">
              <div class="col-md-6 col-lg-6">
                <h2 class="text-center text-bold mb-3">Directeur Général</h2>
                <div class="card-teamG">
                  <img src="images/photodg.jpg" alt="images" />

                  <div class="overlay">
                    <h3>Wilfried Areago</h3>
                  </div>
                </div>
              </div>

              <div class="mb-5">
                <h2 class="text-bold">Direction</h2>
                <p>
                  Chers clients, Au nom de toute l'équipe de Marelux, je tiens à
                  exprimer notre profonde gratitude pour votre fidélité. Votre
                  confiance est notre moteur, et nous sommes honores de faire
                  partie de vos projets. Chez Marelux, notre engagement envers
                  l'excellence, la qualité des produits et votre satisfaction
                  reste constant. Nous sommes là pour vous accompagner dons vos
                  défis, en fournissant des solutions fiables et innovantes.
                  Votre soutien est notre plus gronde force, et nous sommes
                  impatients de continuer à grandir ensemble. Merci de choisir
                  Marelux.
                </p>
              </div>
            </div>

            <div class="section--title">
              <h1>
                Notre <span>Culture</span> d'entreprise
              </h1>
            </div>

            <div class="cd-inf">
              <h2>Notre mission</h2>
              <p>
                Fournir des solutions électriques et de plomberie de haute
                qualité pour répondre aux besoins de nos clients, en assurant
                une satisfaction exceptionnelle et en favorisant la sécurité et
                l'efficacité.
              </p>
            </div>

            <div class="cd-inf">
              <h2>Notre vision</h2>
              <p>
                Devenir le partenaire de confiance de choix dans le domaine de
                la vente de matériel d'électricité et de plomberie, en innovant
                constamment pour rester à la pointe de l'industrie et en
                établissant des relations durables avec nos clients.
              </p>
            </div>

            <div class="cd-inf">
              <h2>Nos valeurs</h2>
              <p>
                Excellence: Nous nous engageons à fournir des produits et
                services de la plus haute qualité, dépassant les attentes de nos
                clients.
                <p>
                  Intégrité: Nous agissons avec transparence, honnêteté et
                  éthique dans toutes nos interactions, bâtissant ainsi la
                  confiance. Innovation: Nous encourageons la créativité et
                  cherchons constamment des moyens novateurs pour améliorer nos
                  produits et processus.
                </p>{" "}
                <p>
                  Engagement envers le client: Nous plaçons les besoins de nos
                  clients au cœur de nos préoccupations et nous efforçons de les
                  satisfaire de manière proactive. Responsabilité sociale et
                  environnementale: Nous sommes déterminés à agir de manière
                  responsable envers la société et l'environnement, contribuant
                  positivement à la communauté
                </p>
              </p>
            </div>
          </div>
        </section>

        <section>
          <div class="container">
            <div class="row wow fadeInRight">
              <div class="col-md-6 col-lg-4">
                <div class="cd-inf">
                  <h1>01</h1>
                  <h2>Excellence</h2>
                  <p>
                    Nous nous engageons à fournir des produits et services de la
                    plus haute qualité, dépassant les attentes de nos clients.
                  </p>
                </div>
              </div>

              <div class="col-md-6 col-lg-4">
                <div class="cd-inf">
                  <h1>02</h1>
                  <h2>Intégrité</h2>
                  <p>
                    Nous agissons avec transparence, honnêteté et éthique dans
                    toutes nos interactions, bâtissant ainsi la confiance.
                  </p>
                </div>
              </div>

              <div class="col-md-6 col-lg-4">
                <div class="cd-inf">
                  <h1>03</h1>
                  <h2>Engagement</h2>
                  <p>
                    Nous plaçons les besoins de nos clients au cœur de nos
                    préoccupations et nous efforçons de les satisfaire de
                    manière proactive.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </section>
      </main>
    </>
  );
}

export default MainMarque;
