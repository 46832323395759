import React from 'react'
import Nav from '../components/Nav'
import HeaderContact from '../components/HeaderContact'
import MainContact from '../components/MainContact'
import Footer from '../components/Footer'

function Contact() {
  return (
    <div className="App">
    <Nav />
    <HeaderContact />
    <MainContact />
    <Footer />
  </div>
  )
}

export default Contact