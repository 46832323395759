import React from "react";

function MainContact() {
  return (
    <main>
      <section>
        <div class="container wow fadeInRight">
          <div class="section--title">
            <h1>
              Vous  <span>Satisfaire</span>, notre <span>credo</span>
            </h1>
          </div>

          <form action="">
            <div class="mb-5">
              <h2 class="bold">Formulaire de contacts</h2>
              <p>
                Nous sommes à votre écoute. Remplissez le formulaire ci-dessous
                et nous vous recontacterons
              </p>
            </div>

            <div class="row">
              <div class="col-md-7 col-lg-8">
                <div>
                  <input type="text" placeholder="Nom*" />
                  <input type="email" name="" id="" placeholder="Emal*" />
                  <input type="text" placeholder="Sujet*" />
                  <textarea name="" id="" placeholder="Message"></textarea>

                  <a href="#" class="link-btn">
                    <span>En savoir +</span>
                  </a>
                </div>
              </div>

              <div class="col-md-5 col-lg-4">
                <div class="card-ct">
                  <p>
                    {" "}
                    <i class="fa-solid fa-location-pin"></i> MARELUX, Abidjan,
                    Bd des Martyrs
                  </p>
                  <p>
                    {" "}
                    <i class="fa-solid fa-phone"></i> Phone: +225 27 22 52 34 11 / +225 0767916951
                  </p>
                  <p>
                    {" "}
                    <i class="fa-solid fa-envelope"></i> Email:
                    contact@marelux-ci.com
                  </p>
                  <p>
                    {" "}
                    <i class="fa-solid fa-clock"></i> Horaires : Lundi - Vendredi: 8H à 17H30 <br/>
                        Samedi: 8H à 14H30
                  </p>
                </div>

                <div class="card-ifr">
                  <iframe
                    src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3972.1541218035604!2d-3.994722925524747!3d5.3934716352637055!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0xfc195a7c39e61cb%3A0x217ac50af0bd80bf!2sMARELUX!5e0!3m2!1sfr!2sci!4v1706270108322!5m2!1sfr!2sci"
                    width="600"
                    height="450"
                    style={{border:"0"}}
                    allowfullscreen=""
                    loading="lazy"
                    referrerpolicy="no-referrer-when-downgrade"
                  ></iframe>
                </div>
              </div>
            </div>
          </form>
        </div>
      </section>
    </main>
  );
}

export default MainContact;
