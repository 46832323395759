import React from 'react'
import Nav from '../components/Nav'
import HeaderMetier from '../components/HeaderMetier'
import MainMetier from '../components/MainMetier'
import Footer from '../components/Footer'

function Metier() {
  return (
    <div className="App">
    <Nav />
    <HeaderMetier />
    <MainMetier />
    <Footer />
  </div>
  )
}

export default Metier