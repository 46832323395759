import React from "react";

function Main() {
  return (
    <main>
      <section>
        <div class="container wow fadeInLeft">
          <div class="section--title">
            <h1>
              Nous nous engageons à fournir des
              produits et services de la plus <span>haute qualité</span>,
              dépassant les attentes de <span>nos clients.</span>
            </h1>
          </div>

          <p class="mb-5">
            Nous intégrons plusieurs métiers au sein de nos pôles de compétences
            :
          </p>

          <div class="row">
            <div class="col-md-6 col-lg-6">
              <div class="card-index">
                <div class="card-index__img">
                  <img
                    src="images/6.jpg"
                    alt="img"
                  />
                </div>

                <div class="card-index__descrip">
                  <h3>Electricité</h3>
                  <a href="#" class="link-btn">
                    <span>En savoir +</span>
                  </a>
                </div>
              </div>
            </div>

            <div class="col-md-6 col-lg-6">
              <div class="card-index">
                <div class="card-index__img">
                  <img
                    src="images/5.jpg"
                    alt="img"
                  />
                </div>

                <div class="card-index__descrip">
                  <h3>Plomberie</h3>
                  <a href="#" class="link-btn">
                    <span>En savoir +</span>
                  </a>
                </div>
              </div>
            </div>
          </div>

          <div class="row mt-3">
            {/* <div class="col-md-6 col-lg-6">
              <p>
                Nos compétences, alignées sur les standards internationaux, nous
                permettent de proposer des produits et services au plus près des
                besoins des entreprises , des investisseurs, des gouvernements,
                des institutions et des particuliers de notre continent.
              </p>
            </div>

            <div class="col-md-6 col-lg-6">
              <p>
                Notre succès ne peut avoir de sens qu’en liant l’exécution de
                nos projets à l’amélioration des conditions de vie des
                communautés au sein desquelles nous sommes.
              </p>
            </div> */}
          </div>
        </div>
      </section>

      {/* <section>
        <div class="container wow fadeInRight">
          <div class="section--title">
            <h1>
              C’est pourquoi <span>notre responsabilité sociale</span> se
              réalise à travers trois engagements d’inclusion sociale
            </h1>
          </div>

          <div class="row">
            <div class="col-md-6 col-lg-4">
              <div class="card-indexBs">
                <div class="card-indexBs__img">
                  <img
                    src="https://kaydangroupe.com/assets/images/elmts2.jpg"
                    alt="img"
                  />

                  <div class="overlay">
                    <h2>01</h2>
                  </div>
                </div>

                <div class="card-indexBs__descrip">
                  <p>
                    Développer la culture Entrepreneuriale en Afrique, avec les
                    actions de <a href="">la Fondation Marelux.</a>{" "}
                  </p>
                </div>
              </div>
            </div>

            <div class="col-md-6 col-lg-4">
              <div class="card-indexBs">
                <div class="card-indexBs__img">
                  <img
                    src="https://kaydangroupe.com/assets/images/elmts2.jpg"
                    alt="img"
                  />

                  <div class="overlay">
                    <h2>01</h2>
                  </div>
                </div>

                <div class="card-indexBs__descrip">
                  <p>
                    Développer la culture Entrepreneuriale en Afrique, avec les
                    actions de <a href="">la Fondation Marelux.</a>{" "}
                  </p>
                </div>
              </div>
            </div>

            <div class="col-md-6 col-lg-4">
              <div class="card-indexBs">
                <div class="card-indexBs__img">
                  <img
                    src="https://kaydangroupe.com/assets/images/elmts2.jpg"
                    alt="img"
                  />

                  <div class="overlay">
                    <h2>01</h2>
                  </div>
                </div>

                <div class="card-indexBs__descrip">
                  <p>
                    Développer la culture Entrepreneuriale en Afrique, avec les
                    actions de <a href="">la Fondation Marelux.</a>{" "}
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section> */}
    </main>
  );
}

export default Main;
