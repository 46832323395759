import React, { useState } from "react";
import { Link, useLocation } from "react-router-dom";
function Nav() {
  const location = useLocation();

  const [show, setShow] = useState(false);

  const showMenu = () => {
    setShow(true);
  };

  const hideMenu = () => {
    setShow(false);
  };
  return (
    <div className="navigation">
      <div className="container nav--c">
        <div className="nav-top">
          <div className="logo">
            <Link to="/">
              <img src="images/Logo-page-dacceuil.jpg" alt="logo" />
            </Link>
          </div>
          <div className="nav-top__ct">
            <div className="d-flex align-items-center">
              <div className="mt-3">
                <p>+225 27 22 52 34 11</p>

                <span>
                  <a href="">Envoyez-nous vos devis</a>
                </span>
              </div>
              <i className="fa-solid fa-mobile"></i>
            </div>
            <p>
              <a href="">FR</a>
            </p>
          </div>
          <div className="menu--enter" onClick={showMenu}>
            <i className="fa fa-bars"></i>
          </div>
        </div>
        {/* Menu */}
        <div>
          <div className={show ? "menu show" : "menu"}>
            <ul>
              <li className="menu--exit" onClick={hideMenu}>
                <i className="fa fa-times"></i>
              </li>
              <li className={location.pathname === "/" ? "act" : ""}>
                <Link to="/">Accueil</Link>
              </li>
              <li className={location.pathname === "/marque" ? "act" : ""}>
                <Link to="/marque">A Propos</Link>
              </li>
              <li>
                <Link to="/metier">
                  Nos Services <i className="fa-solid fa-angle-down"></i>
                </Link>
                <ul className="sub-menu">
                  <li>
                    <Link to="/metier">
                      {" "}
                      <i className="fa-solid fa-angles-right"></i> Electricité
                    </Link>
                  </li>
                  <li>
                    <Link to="/metier">
                      {" "}
                      <i className="fa-solid fa-angles-right"></i> Plomberie
                    </Link>
                  </li>
                </ul>
              </li>
              {/* <li>
                <Link to="/team">équipe </Link>
              </li> */}
              <li>
                <Link to="/contact">Nous joindre</Link>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Nav;
