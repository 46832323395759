import React from "react";

function HeaderContact() {
  return (
    <header>
      <div class="header--top  ">
        <img
          src="images/2.jpg"
          alt="header"
        />

        <div class="overlay">
          <div class="container">
            <div class="title--infos">
              <h1>Nos contacts</h1>

              
            </div>
          </div>
        </div>
      </div>
    </header>
  );
}

export default HeaderContact;
