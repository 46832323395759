import React from 'react'

function Header() {
    return (
      <header>
        <div className="header--top ">
          <img src="images/3.jpg" alt="header" />
          <div className="overlay">
            <div className="container">
              <div className="title--infos">
                <h1>Accueil</h1>
                <nav aria-label="breadcrumb">
                  <ol className="breadcrumb">
                    {/* <li className="breadcrumb-item"><a href="#">CROIRE EN DEMAIN AVEC VOUS</a></li> */}
                  </ol>
                </nav>
              </div>
            </div>
          </div>
        </div>
      </header>
    );
  }
  

export default Header