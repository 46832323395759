import React from 'react'
import Nav from '../components/Nav'
import Footer from '../components/Footer'
import MainMarque from '../components/MainMarque'
import HeaderMarque from '../components/HeaderMarque'

function Marque() {
    return (
        <div className="App">
        <Nav />
        <HeaderMarque />
        <MainMarque />
        <Footer />
      </div>
    )
   
}

export default Marque