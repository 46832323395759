import './App.css';
import { Routes, Route } from "react-router-dom";
import Home from './pages/Home';
import Marque from './pages/Marque';
import Metier from './pages/Metier';
import Team from './pages/Team';
import Contact from './pages/Contact';

function App() {
  return (
    <Routes>
      <Route path="/" element={<Home/>} />
      <Route path="metier" element={<Metier/>} />
      <Route path="marque" element={<Marque/>} />
      {/* <Route path="/team" element={<Team/>} /> */}
      <Route path="/contact" element={<Contact/>} />
    </Routes>
  );
}

export default App;
