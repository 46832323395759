import React from 'react'

function HeaderMetier() {
  return (
    <header>
    <div class="header--top  ">
        <img src="images/1.jpg" alt="header"/>

        <div class="overlay">
            <div class="container">
                <div class="title--infos">
                    <h1>Nos Services</h1>

                    
                    {/* <nav  aria-label="breadcrumb">
                        <ol class="breadcrumb">
                          <li class="breadcrumb-item"><a href="index.html">Le groupe |</a></li>
                          <li class="breadcrumb-item active" aria-current="page">Nos métiers</li>
                        </ol>
                    </nav> */}
                </div>
            </div>
        </div>
    </div>
</header>
  )
}

export default HeaderMetier